// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-layouts-post-js": () => import("./../src/layouts/post.js" /* webpackChunkName: "component---src-layouts-post-js" */),
  "component---src-layouts-workout-js": () => import("./../src/layouts/workout.js" /* webpackChunkName: "component---src-layouts-workout-js" */),
  "component---src-layouts-workout-listing-js": () => import("./../src/layouts/workout-listing.js" /* webpackChunkName: "component---src-layouts-workout-listing-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-donate-js": () => import("./../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-posts-js": () => import("./../src/pages/posts.js" /* webpackChunkName: "component---src-pages-posts-js" */),
  "component---src-pages-story-js": () => import("./../src/pages/story.js" /* webpackChunkName: "component---src-pages-story-js" */)
}

